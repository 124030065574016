import * as yup from "yup";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import useSmartForm from "../utils/smart-form";
import {
	asyncRemoveFromShoppingCart,
	asyncSetQuantity
} from "../data/slices/place-order";
import {
	Button, CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton, makeStyles, SvgIcon, Tooltip,
	Typography
} from "@material-ui/core";
import clsx from "clsx";
import * as Icons from "../icons";
import NumberFormat from "react-number-format";
import HtmlTooltip from "./html-tooltip";
import {styles} from "../utils/styles";
import FormTextField from "./form-text-field";
import store from "../data/store";
import {LocalOffer} from "@material-ui/icons";
import Spacer from "./spacer";

const useStyles = makeStyles((theme) => ({
	...styles,
	textRight: {
		textAlign: "right"
	},
	redColor: {
		color: "#F44336"
	},
	buttonProgress: {
		color: theme.palette.secondary.light,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	iconColor: {
		color: 'rgba(0, 0, 0, 0.54)'
	},
	warn: {
		color: theme.palette.warning.main
	}
}));

const quantityChangeSchema = yup.object().shape({
	quantity: yup.number().required("Quantity is required").positive("Quantity must be positive").integer("Quantity must be a whole number").typeError("Quantity must be a number").moreThan(0, "Quantity must be non-zero")
});

function GetQuantityCell({gridCellParams}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const cartItem = gridCellParams.row;
	const {enqueueSnackbar} = useSnackbar();
	const [quantitySaving, setQuantitySaving] = useState(false);
	const {handleSubmit, formState: {errors, ...formState}, reset, control, getValues} = useSmartForm({
		schema: quantityChangeSchema,
		defaultValues: {
			"quantity": cartItem.quantity
		}
	});

	const updateQuantity = async (data) => {
		if (quantitySaving) {
			return;
		}

		setQuantitySaving(true);
		try {
			await dispatch(asyncSetQuantity({item: cartItem, quantity: data.quantity}));
			reset({quantity: data.quantity}, {
				keepErrors: false,
				keepDirty: false,
				keepValues: true,
				keepDefaultValues: false,
				keepIsSubmitted: false,
				keepTouched: false,
				keepIsValid: false,
				keepSubmitCount: false
			});
		} catch (e) {
			enqueueSnackbar("Quantity update failed!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
		} finally {
			setQuantitySaving(false);
		}
	}

	return (
		<FormTextField
			formProps={{name: 'quantity', control: control}}
			className={"flex25"}
			variant="outlined"
			onBlur={(e) => {
				if (parseInt(getValues('quantity')) > parseInt(cartItem.available) && (cartItem.code === "D" || cartItem.specialProduct)) {
					const message = cartItem.specialProduct ?
						"This uses special pricing. Quantity ordered cannot exceed available for this product." :
						"This is a discontinued product. Quantity ordered cannot exceed available for this product.";

					enqueueSnackbar(message, {
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
						variant: "warning"
					});

					reset({}, {
						keepErrors: false,
						keepDirty: false,
						keepDefaultValues: true,
						keepIsSubmitted: false,
						keepTouched: false,
						keepIsValid: false,
						keepSubmitCount: false
					});
				} else if (formState.isValid && formState.isDirty) {
					handleSubmit(updateQuantity)();
				} else if (formState.isDirty) {
					reset({}, {
						keepErrors: false,
						keepDirty: false,
						keepDefaultValues: true,
						keepIsSubmitted: false,
						keepTouched: false,
						keepIsValid: false,
						keepSubmitCount: false
					});
				}
			}}
			onFocus={(e) => e.target.select()}
			inputProps={{
				className: classes.textRight
			}}
			helperText={null}
			disabled={quantitySaving || gridCellParams.row.isBogo}
		/>
	);
}

function GetActionsCell({gridCellParams}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const {enqueueSnackbar} = useSnackbar();
	const userRole = store.getState().users.currentUser.userRole;
	const item = gridCellParams.row;
	const [dialogOpen, setDialogOpen] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const inSepMode = userRole === "EPP";

	async function confirmDelete() {
		if (deleting) {
			return;
		}
		setDeleting(true);
		try {
			await dispatch(asyncRemoveFromShoppingCart(item));
			enqueueSnackbar("Product removed successfully!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "success"
			});
			setDeleting(false);
			setDialogOpen(false);
		} catch (e) {
			enqueueSnackbar("Product removal failed!", {
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				variant: "error"
			});
			setDeleting(false);
		}
	}

	return (
		<>
			<div className={clsx(classes.row, inSepMode ? classes.alignCenterCenter : classes.alignEndCenter)}>
				{!inSepMode &&
					<>
					{gridCellParams.row.specialProduct ?
						<>
							<Tooltip title="Item is a Special Opportunity product">
								<div className={clsx(classes.column, classes.alignCenterCenter)}>
									<SvgIcon component={Icons.SpecialOpportunities} viewBox="-2 -2 24 24" style={{ color: '#49c914' }}/>
								</div>
							</Tooltip>
							<Spacer width={4} />
						</> :
						gridCellParams.row.specialPricingAvailable ? (
							<>
								<Tooltip title="Special Opportunity pricing available">
									<div className={clsx(classes.column, classes.alignCenterCenter)}>
										<SvgIcon component={Icons.SpecialOpportunities}  style={{ color: '#f44336' }}/>
									</div>
								</Tooltip>
								<Spacer width={4} />
							</>
						) : <span/>
					}
				</>
				}
				{gridCellParams.row.isBogo &&
					<>
						<Tooltip title="BOGO Item">
							<div className={clsx(classes.column, classes.alignCenterCenter)}>
								<LocalOffer style={{ color: '#49c914'}}/>
							</div>
						</Tooltip>
						<Spacer width={4} />
					</>
				}
				<IconButton onClick={() => setDialogOpen(true)} disabled={gridCellParams.row.isBogo}>
					<SvgIcon  color={gridCellParams.row.isBogo ? 'disabled' : 'error'} component={Icons.Trash} viewBox="-4.5 -2 24 24"/>
				</IconButton>
			</div>
			<Dialog open={dialogOpen} disableBackdropClick={true} disableEscapeKeyDown={true}>
				<DialogTitle>Remove Item</DialogTitle>
				<DialogContent>
					<Typography>Are you sure you want to remove {item.prodId} - {item.description} from your shopping
						cart?</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)} disabled={deleting}>Cancel</Button>
					<div className={classes.relativePosition}>
						<Button className={classes.redColor} onClick={confirmDelete} disabled={deleting}>Remove</Button>
						{deleting && <CircularProgress size={24} className={classes.buttonProgress}/>}
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
}

function DescriptionHeader() {
	const classes = useStyles();

	return (
		<div className={clsx(classes.row, classes.alignStartCenter)}>
			<div className="MuiDataGrid-colCellTitle">Description</div>
			&nbsp;
			<Tooltip title="Hover over descriptions to see full descriptions" placement="top">
				<div className={clsx(classes.column, classes.alignCenterStart)}>
					<SvgIcon className={classes.iconColor} component={Icons.Information} viewBox="-2 -2 24 24"/>
				</div>
			</Tooltip>
		</div>
	);
}

const shoppingCartColumns = [
	// {
	// 	field: 'status',
	// 	headerName: 'Est. Availability',
	// 	headerClassName: 'grid-column-headers',
	// 	headerAlign: 'center',
	// 	sortComparator: getStatusComparator,
	// 	type: 'string',
	// 	align: 'center',
	// 	flex: 1,
	// 	renderCell: (gridCellParams) => {
	// 		const available = gridCellParams.getValue('available');
	// 		const cartQuantity = gridCellParams.getValue('quantity');
	// 		const netAvailable = available - cartQuantity;
	//
	// 		if (gridCellParams.row.code === "S") {
	// 			return "Open Box";
	// 		} else if (netAvailable >= 0 && available !== 0) {
	// 			return "Now";
	// 		} else if (netAvailable < 0 || available === 0) {
	// 			if (gridCellParams.row.code === "O") {
	// 				return <SpecialOrderLabel/>;
	// 			} else if (gridCellParams.row.dueInDate) {
	// 				return (
	// 					<>
	// 						<Moment format="MM/DD/YYYY">
	// 							{gridCellParams.row.dueInDate.substr(0, gridCellParams.row.dueInDate.length - 1)}
	// 						</Moment>
	// 					</>
	// 				);
	// 			} else {
	// 				return "TBD";
	// 			}
	// 		} else {
	// 			return "TBD";
	// 		}
	// 	}
	// },
	{
		field: 'available',
		headerName: 'Available',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				value={gridCellParams.row.available < 0 ? 0 : gridCellParams.row.available}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'quantity',
		headerName: 'Ordered',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<GetQuantityCell gridCellParams={gridCellParams}/>
	},
	{
		field: 'now',
		headerName: 'Now',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) => {
			const available = gridCellParams.getValue('available');
			const cartQuantity = gridCellParams.getValue('quantity');
			const backOrder =  cartQuantity - available;
			const now = backOrder < 0 ? cartQuantity : available;

			return <NumberFormat
				thousandSeparator={true}
				value={now}
				defaultValue={0}
				displayType="text"
			/>
		}

	},
	{
		field: 'backOrdered',
		headerName: 'Back Order',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) => {
			const available = gridCellParams.getValue('available');
			const cartQuantity = gridCellParams.getValue('quantity');
			const backOrder =  cartQuantity - available;

			return <NumberFormat
							thousandSeparator={true}
							value={cartQuantity === 0 ? 0 : (backOrder < 0 ? 0 : backOrder)}
							defaultValue={0}
							displayType="text"
						/>

		}
	},
	{
		field: 'prodId',
		headerName: 'Product ID',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		type: 'string',
		flex: 1
	},
	{
		field: 'description',
		headerName: 'Description',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		renderHeader: (gridColumnHeaderParams) => {
			return <DescriptionHeader/>
		},
		type: 'string',
		flex: 2,
		renderCell: (gridCellParams) => (
			<HtmlTooltip
				arrow={true}
				placement="top"
				title={
					<>
						<Typography color="inherit">Full Product Description</Typography>
						{gridCellParams.row.extraDescription ? gridCellParams.row.extraDescription : gridCellParams.row.description}
					</>
				}
			>
				<span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{gridCellParams.row.extraDescription ? gridCellParams.row.extraDescription : gridCellParams.row.description}</span>
			</HtmlTooltip>
		)
	},
	{
		field: 'brand',
		headerName: 'Brand',
		headerClassName: 'grid-column-headers',
		headerAlign: 'center',
		type: 'string',
		flex: 1,
		valueGetter: (gridValueGetterParams) => {
			const shoppingCartItem = gridValueGetterParams.row;
			const categoriesMap = store.getState().categories.categoriesMap;
			const match = categoriesMap[shoppingCartItem.category];

			return match ? match.name : null;
		}
	},
	{
		field: 'dealerNet',
		headerName: 'Dealer Net',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.dealerNet}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'actualPrice',
		headerName: 'Your Cost',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.actualPrice}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'extended',
		headerName: 'Extended',
		headerClassName: 'grid-column-headers',
		type: 'number',
		flex: 1,
		renderCell: (gridCellParams) =>
			<NumberFormat
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
				prefix="$"
				value={gridCellParams.row.actualPrice * gridCellParams.row.quantity}
				defaultValue={0}
				displayType="text"
			/>
	},
	{
		field: 'actions',
		headerName: " ",
		align: 'right',
		width: 125,
		filterable: false,
		resizable: false,
		sortable: false,
		renderCell: (gridCellParams) => <GetActionsCell gridCellParams={gridCellParams}/>
	}
];

function getShoppingCartColumns() {
	const userRole = store.getState().users.currentUser.userRole;

	if (userRole === "EPP") {
		let retColumns = shoppingCartColumns.filter((itm) => {
			return itm.field !== "available";
		});

		retColumns = retColumns.map((itm) => {
			if (itm.field === "dealerNet") {
				return {
					field: 'dealerNet',
					headerName: 'MAP/Street',
					headerClassName: 'grid-column-headers',
					type: 'number',
					flex: 1,
					valueGetter: (gridValueGetterParams) => {
						if (gridValueGetterParams.row.map > 0) {
							return gridValueGetterParams.row.map;
						} else if (gridValueGetterParams.row.street > 0) {
							return gridValueGetterParams.row.street;
						} else {
							return "N/A";
						}
					},
					renderCell: (gridCellParams) => {
						if (gridCellParams.value !== "N/A") {
							return <NumberFormat
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix="$"
								value={gridCellParams.value}
								defaultValue={0}
								displayType="text"
							/>
						} else {
							return gridCellParams.value;
						}
					}
				}
			} else {
				return itm;
			}
		})

		return retColumns;
	} else {
		return shoppingCartColumns;
	}
}

export default getShoppingCartColumns;