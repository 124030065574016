import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetAction } from "../../utils/common-actions";
import { useSnackbar } from "notistack";

const axios = require('axios');

const getShoppingCart = createAsyncThunk(
	'placeOrder/getShoppingCart',
	async (arg, thunkAPI) => {
		try {
			const response = await axios.get('/shoppingCart', {
				params: {
					vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
				}
			});
			return response.data;
		} catch (e) {
			return [];
		}
	}
);

const asyncAddToShoppingCart = createAsyncThunk(
	'placeOrder/addToShoppingCart',
	async (arg, thunkAPI) => {
		const response = await axios.post('/shoppingCart', {
			...arg,
			vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
		});

		if (response.data.hasDuplicate) {
			return { hasDuplicate: true, message: response.data.message }
		}

		return response.data;
	}
)

const asyncRemoveFromShoppingCart = createAsyncThunk(
	'placeOrder/removeFromShoppingCart',
	async (arg, thunkAPI) => {
		let ret = await axios.delete(`/shoppingCart/${arg.id}`, {
			params: {
				vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
			}
		});

		return ret.data;
	}
)

const asyncSetQuantity = createAsyncThunk(
	'placeOrder/setQuantity',
	async (arg, thunkAPI) => {
		let ret = await axios.put(`/shoppingCart/${arg.item.id}`, {
			quantity: arg.quantity,
			vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
		});

		return ret.data;
	}
)

const asyncGetCategoryProducts = createAsyncThunk(
	'placeOrder/getCategoryProducts',
	async ({ categoryId, ...filterProps }, thunkAPI) => {
		const response = await axios.get(`/products/listCategory`, {
			params: {
				categoryId: categoryId,
				...filterProps,
				vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
			}
		});

		return response.data;
	}
)

const asyncGetCategoryProductsSpecial = createAsyncThunk(
	'placeOrder/getCategoryProductsSpecial',
	async ({ categoryId }, thunkAPI) => {
		const response = await axios.get(`/products/listCategorySpecial`, {
			params: {
				categoryId: categoryId,
				vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
			}
		});

		return response.data;
	}
)

const asyncGetAllCategoryProductsSpecial = createAsyncThunk(
	'placeOrder/getAllCategoryProductsSpecial',
	async ({ categoryId }, thunkAPI) => {
		const response = await axios.get(`/products/listAllCategorySpecial`, {
			params: {
				vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
			}
		});

		return response.data;
	}
)

const asyncSetQuantityFromProductList = createAsyncThunk(
	'placeOrder/setQuantityFromProductList',
	async ({ prodId, quantity, fromSpecial }, thunkAPI) => {
		const shoppingCart = thunkAPI.getState().placeOrder.shoppingCart;
		const itemExists = shoppingCart.find((itm) => itm.prodId === prodId && itm.specialProduct === fromSpecial);

		if (itemExists) {
			if (quantity === 0 || quantity === "0") {
				let ret = await axios.delete(`/shoppingCart/${itemExists.id}`, {
					params: {
						vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
					}
				});

				return ret.data;
			} else {
				let ret = await axios.put(`/shoppingCart/${itemExists.id}`, {
					quantity,
					vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
				});

				return ret.data;
			}
		} else {
			if (quantity === 0 || quantity === "0") {
				return { existed: false, item: null, quantity: quantity };
			} else {
				const response = await axios.post('/shoppingCart', {
					prodId,
					quantity,
					vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id,
					fromSpecial
				});

				return response.data;
			}
		}
	}
)

const clearShoppingCart = createAsyncThunk(
	'placeOrder/clearShoppingCart',
	async (_, thunkAPI) => {
		await axios.post('/clearShoppingCart', {
			vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
		});
	}
)

const getCheckoutSession = createAsyncThunk(
	'placeOrder/getCheckoutSession',
	async (_, thunkAPI) => {
		return await axios.get('/checkoutSession', {
			params: {
				vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
			}
		});
	}
)

const submitOrder = createAsyncThunk(
	'placeOrder/submitOrder',
	async ({
					 vicariousCompany,
					 shippingAddress,
					 eppBillingAddress,
					 shipComplete,
					 hotOrder,
					 onMemo,
					 chargeCard,
					 shippingMethod,
					 poNumber,
					 emails,
					 notes,
					 total,
					 subtotal,
					 shipping,
					 discount,
					 salesTax,
					 cardInfo,
					 paymentProfileToUse
				 }, thunkAPI) => {
		return await axios.post('/submitOrder', {
			vicariousCompany,
			shippingAddress,
			eppBillingAddress,
			shipComplete,
			hotOrder,
			onMemo,
			chargeCard,
			shippingMethod,
			poNumber,
			emails,
			notes,
			total,
			subtotal,
			shipping,
			discount,
			salesTax,
			cardInfo,
			paymentProfileToUse
		});
	}
)

const createEmail = createAsyncThunk(
	'placeOrder/createEmail',
	async ({ email }, thunkAPI) => {
		return await axios.post("/createEmail", {
			email: email.trim(),
			vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id
		});
	}
)

const editEmail = createAsyncThunk(
	'placeOrder/updateEmail',
	async ({ id, email }, thunkAPI) => {
		return await axios.put("/updateEmail", {
			id: id,
			newEmail: email.trim()
		});
	}
)

const deleteEmail = createAsyncThunk(
	'placeOrder/deleteEmail',
	async ({ id }, thunkAPI) => {
		return await axios.delete("/deleteEmail", {
			params: {
				id: id
			}
		});
	}
)

const filterHistory = createAsyncThunk(
	'placeOrder/filterHistory',
	async ({ startDate, endDate, orderType, orderNumber, poNumber, productId, page }, thunkAPI) => {
		return await axios.get("/orderHistory", {
			params: {
				startDate,
				endDate,
				orderType,
				orderNumber,
				poNumber,
				productId,
				vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id,
				page
			}
		});
	}
)

const getOrderDetails = createAsyncThunk(
	'placeOrder/getOrderDetails',
	async (order, thunkAPI) => {
		return await axios.get("/orderDetails", {
			params: {
				archived: order.archived,
				invoiceNumber: order.invoiceNumber,
				orderNumber: order.orderNumber,
				boNumber: order.boNumber,
				vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id,
			}
		});
	}
)

const getOrderTracking = createAsyncThunk(
	'placeOrder/getOrderTracking',
	async (order, thunkAPI) => {
		return await axios.get("/orderTracking", {
			params: {
				orderNumber: order.orderNumber,
				boNumber: order.boNumber,
				vicariousCompany: thunkAPI.getState().placeOrder.orderingFor?.id,
			}
		});
	}
)

const getPaymentMethods = createAsyncThunk(
	'placeOrder/getPaymentMethods',
	async (payload, thunkAPI) => {
		return await axios.get("/paymentMethods");
	}
)

export {
	getShoppingCart,
	asyncAddToShoppingCart,
	asyncRemoveFromShoppingCart,
	asyncSetQuantity,
	asyncGetCategoryProducts,
	asyncGetCategoryProductsSpecial,
	asyncGetAllCategoryProductsSpecial,
	asyncSetQuantityFromProductList,
	clearShoppingCart,
	getCheckoutSession,
	submitOrder,
	createEmail,
	editEmail,
	deleteEmail,
	filterHistory,
	getOrderDetails,
	getOrderTracking,
	getPaymentMethods
};

const initialState = {
	shoppingCartLoading: false,
	shoppingCart: [],
	orderingFor: null,
	promptOrderingFor: false,
	products: [],
	specialProducts: [],
	allSpecialProducts: [],
	checkoutCustomer: null,
	checkoutShippingAddresses: [],
	checkoutEmails: [],
	orderHistory: [],
	orderHistoryTotalCount: 0,
	orderHistoryDetails: [],
	orderHistoryTracking: [],
	paymentMethods: [],
	checkoutShippingMethods: [
		{ normal: "UPS Ground", weird: "UPS GND" },
		{ normal: "UPS 3 Day Select", weird: "UPS ORANGE" },
		{ normal: "UPS Second Day", weird: "UPS BLUE" },
		{ normal: "UPS Next Day", weird: "UPS RED" },
		{ normal: "UPS Next Day Early", weird: "UPS RED AM" },
		{ normal: "FedEx Ground", weird: "FDX GND PPD" },
		{ normal: "FedEx Express Saver", weird: "FDX 3 DAY" },
		{ normal: "FedEx Second Day", weird: "FDX 2 DAY" },
		{ normal: "FedEx Overnight", weird: "FDX STD NDA" },
		{ normal: "FedEx Priority Overnight", weird: "FDX P 1" },
		{ normal: "USPS", weird: "USPS" }
	],

};

export const placeOrderSlice = createSlice({
	name: 'placeOrder',
	initialState: initialState,
	reducers: {
		setShoppingCartLoading: (state, action) => {
			state.shoppingCartLoading = action.payload;
		},
		setOrderingFor: (state, action) => {
			state.orderingFor = action.payload;
		},
		setPromptOrderingFor: (state, action) => {
			state.promptOrderingFor = action.payload;
		},
		addCustomShippingAddress: (state, action) => {
			state.checkoutShippingAddresses = [...state.checkoutShippingAddresses, action.payload];
		},
		replaceShippingAddresses: (state, action) => {
			state.checkoutShippingAddresses = [action.payload];
		}
	},
	extraReducers: {
		[getShoppingCart.fulfilled]: (state, action) => {
			state.shoppingCart = action.payload;
		},
		[asyncSetQuantity.fulfilled]: (state, action) => {
			if(action.payload.succeeded){
				state.shoppingCart = action.payload.shoppingCart;
			}
		},
		[asyncRemoveFromShoppingCart.fulfilled]: (state, action) => {
			if(action.payload.succeeded){
				state.shoppingCart = action.payload.shoppingCart;
			}
		},
		[asyncAddToShoppingCart.fulfilled]: (state, action) => {
			if(action.payload.succeeded){
				state.shoppingCart = action.payload.shoppingCart;
			}
		},
		[asyncGetCategoryProducts.fulfilled]: (state, action) => {
			state.products = action.payload;
		},
		[asyncGetCategoryProductsSpecial.fulfilled]: (state, action) => {
			state.specialProducts = action.payload;
		},
		[asyncGetAllCategoryProductsSpecial.fulfilled]: (state, action) => {
			state.allSpecialProducts = action.payload;
		},
		[asyncSetQuantityFromProductList.fulfilled]: (state, action) => {
			if(action.payload.succeeded){
				state.shoppingCart = action.payload.shoppingCart;
			}
		},
		[getCheckoutSession.fulfilled]: (state, action) => {
			const responseBody = action.payload.data;
			state.checkoutShippingAddresses = responseBody.shippingAddresses;
			state.checkoutEmails = responseBody.emails.sort((a, b) => a.address.localeCompare(b.address));
			state.checkoutCustomer = responseBody.customer;
			state.paymentMethods = responseBody.paymentMethods;
		},
		[submitOrder.fulfilled]: (state, action) => {
			if (action.payload.data.succeeded) {
				state.checkoutCustomer = null;
				state.checkoutShippingAddresses = [];
				state.checkoutEmails = [];
			}
		},
		[createEmail.fulfilled]: (state, action) => {
			const data = action.payload.data;
			if (data.succeeded) {
				const id = data.id;
				const newEmail = data.newEmail;
				const newArr = Array.from(state.checkoutEmails);
				newArr.push({ realId: id, address: newEmail });
				state.checkoutEmails = newArr.sort((a, b) => a.address.localeCompare(b.address));
			}
		},
		[editEmail.fulfilled]: (state, action) => {
			const data = action.payload.data;
			if (data.succeeded) {
				const id = data.id;
				const newEmail = data.newEmail;
				const newArr = Array.from(state.checkoutEmails);
				const indexOfEditedRecord = newArr.findIndex((itm) => itm.realId === id);
				newArr.splice(indexOfEditedRecord, 1, { realId: id, address: newEmail });
				state.checkoutEmails = newArr.sort((a, b) => a.address.localeCompare(b.address));
			}
		},
		[deleteEmail.fulfilled]: (state, action) => {
			const data = action.payload.data;
			if (data.succeeded) {
				const id = data.id;
				const newArr = Array.from(state.checkoutEmails);
				const indexOfEditedRecord = newArr.findIndex((itm) => itm.realId === id);
				newArr.splice(indexOfEditedRecord, 1);
				state.checkoutEmails = newArr.sort((a, b) => a.address.localeCompare(b.address));
			}
		},
		[filterHistory.fulfilled]: (state, action) => {
			if (action.payload.data.succeeded) {
				state.orderHistory = action.payload.data.data;
				state.orderHistoryTotalCount = action.payload.data.totalCount;
			}
		},
		[getOrderDetails.fulfilled]: (state, action) => {
			if (action.payload.data.succeeded) {
				state.orderHistoryDetails = action.payload.data.data;
			} else {
				state.orderHistoryDetails = [];
			}
		},
		[getOrderTracking.fulfilled]: (state, action) => {
			if (action.payload.data.succeeded) {
				state.orderHistoryTracking = action.payload.data.data;
			} else {
				state.orderHistoryTracking = [];
			}
		},
		[getPaymentMethods.fulfilled]: (state, action) => {
			state.paymentMethods = action.payload.data;
		},
		[resetAction]: (state, action) => {
			return initialState;
		}
	}
});

export const {
	setShoppingCartLoading,
	addToShoppingCart,
	removeFromShoppingCart,
	setOrderingFor,
	setPromptOrderingFor,
	setQuantity,
	addCustomShippingAddress,
	replaceShippingAddresses
} = placeOrderSlice.actions;

export default placeOrderSlice.reducer;