import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import {styles} from "../utils/styles";
import clsx from "clsx";
import * as React from "react";
import {useEffect, useState} from "react";
import useSmartForm from "../utils/smart-form";
import * as yup from "yup";
import FormDatePicker from "../components/form-date-picker";
import Spacer from "../components/spacer";
import FormTextField from "../components/form-text-field";
import {autocompleteRenderInput, useAutocomplete, useDefaults} from "../utils/autocomplete";
import FormAutocomplete from "../components/form-autocomplete";
import {uuidv4} from "../utils/use-form";
import {Add, Delete} from "@material-ui/icons";
import NumberFormat from "react-number-format";
import {searchEduProducts, searchEduSchools} from "../utils/common-requests";
import {Autocomplete} from "@material-ui/lab";
import CurrencyTextField from "../components/currency-text-field";
import {useDispatch, useSelector} from "react-redux";
import {setPromptOrderingFor} from "../data/slices/place-order";
import {useSnackbar} from "notistack";
import axios from "axios";
import {serializeDate} from "../utils/utilities";
import {usStates} from "../utils/states";
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import moment from "moment";
import PdfFilePickerButton from "../components/invoice-file-uploader";

const useStyles = makeStyles({
  ...styles,
  firstRowMargin: {
    marginTop: 36,
    marginBottom: 36
  },
  headerFontSize: {
    fontSize: 28
  },
  headerInput: {
    boxSizing: 'content-box'
  },
  dataGrid: {
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    display: 'flex',
  },
  tableColumnHeaders: {
    color: "#246dff",
    fontFamily: "'WotfardMedium', sans-serif",
    fontSize: 14
  },
});

function EntryRow({row, setAllRows}){
  const classes = useStyles();
  const autocompleteDefaults = useDefaults();
  const [setProductSearch, productResults, productsIsLoading] = useAutocomplete(searchEduProducts);
  const canEdit = !row.isApproved;

  return(
    <TableRow>
      <TableCell align='left' component="th" scope="row" width={200}>
        <Autocomplete
          {...autocompleteDefaults}
          style={{width: 200}}
          // className="flex"
          getOptionLabel={(x) => `${x.prodId}`}
          filterOptions={(options, _) => options}
          renderOption={(x) => (
            <div className={classes.column}>
              <b>{x.prodId}</b>
              <span>{x.extraDescription ? x.extraDescription : x.description}</span>
            </div>
          )}
          disabled={!canEdit}
          loading={productsIsLoading}
          options={productResults}
          getOptionSelected={(o, v) => {
            return o.prodId === v.prodId
          }}
          value={row.product}
          onInputChange={(e, value, reason) => setProductSearch(value)}
          onChange={async (e, v, r) => {
            if (v) {
              const updatedRow = {
                ...row,
                product: v,
                listPrice: v.listPrice,
                rebateDue: row.quantity * v.rebate,
                description: v.description
              }
              setAllRows((prev) => {
                const idx = prev.findIndex(x => x.id === row.id);
                prev[idx] = updatedRow;
                return [...prev];
              })
            }else{
              const updatedRow = {
                ...row,
                product: null,
                listPrice: 0,
                rebateDue: 0,
                description: ''
              }
              setAllRows((prev) => {
                const idx = prev.findIndex(x => x.id === row.id);
                prev[idx] = updatedRow;
                return [...prev];
              })
            }
          }}
          renderInput={(tParams) => (
            <TextField
              {...tParams}
              size="small"
              variant='outlined'
              disabled={!canEdit}
              InputProps={{
                ...tParams.InputProps,
                endAdornment: (
                  <>
                    {productsIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {tParams.InputProps.endAdornment}
                  </>
                ),
              }}
            />

          )}
        />
      </TableCell>
      <TableCell align='right' width={200}>
        <CurrencyTextField
          value={row.quantity}
          autoNumericOptions={{
            decimalPlaces: 0,
            currencySymbol: '',
          }}
          disabled={!canEdit}
          onBlur={(e, valAsNumber) => {
            const updatedRow = {
              ...row,
              quantity: valAsNumber,
              rebateDue: valAsNumber * (row.product?.rebate || 0)
            }
            setAllRows((prev) => {
              const idx = prev.findIndex(x => x.id === row.id);
              prev[idx] = updatedRow;
              return [...prev];
            })
          }}
        />
      </TableCell>
      <TableCell align='left'>
        {row.description}
      </TableCell>
      <TableCell align='right'>
        <NumberFormat
          thousandSeparator={true}
          value={row.listPrice}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={true}
          prefix="$"
        />
      </TableCell>
      <TableCell align='right'>
        <NumberFormat
          thousandSeparator={true}
          value={row.rebateDue}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={true}
          prefix="$"
        />
      </TableCell>
      <TableCell align='center' width={60}>
        <Button
          disabled={!canEdit}
          onClick={() => {
            setAllRows((prev) => {
              return prev.filter(x => x.id !== row.id);
            })
          }}
        >
          <Delete color={canEdit ? 'error' : 'disabled'}/>
        </Button>
      </TableCell>
    </TableRow>
  )
}

const rebateSchema = yup.object().shape({
  invoiceDate: yup.date('Must be a valid date').typeError('Must be a valid date').nullable().required('This is required')
    .test('invoiceDate', "Invoice date must be on or after 09/01/2024", (value) => {
    return moment(value).isSameOrAfter((moment("2024/09/01")));
  }),
  invoiceNumber: yup.string().required('This is required'),
  studentName: yup.string().required('This is required'),
  school: yup.object().nullable().required('This is required'),
  schoolName: yup.string().when('school', (school, schema) => {
    return school?.schoolId === "MANUAL" ? schema.max(25, "Must be 25 characters or less").required('This is required') : schema;
  }),
  schoolCity: yup.string().when('school', (school, schema) => {
    return school?.schoolId === "MANUAL" ? schema.max(16, "Must be 16 characters or less").required('This is required') : schema;
  }),
  schoolState: yup.string().when('school', (school, schema) => {
    return school?.schoolId === "MANUAL" ? schema.min(2, "Must be 2 characters").max(2, "Must be 2 characters").required('This is required') : schema;
  }),
  debitMemoNumber: yup.string().max(25, "Must be 25 characters or less")
});

const defaultRow = {
  id: uuidv4(),
  product: null,
  quantity: 1,
  description: '',
  listPrice: 0.00,
  rebateDue: 0.00,
  isApproved: 0
}

function PhotoEduRebates() {
  const classes = useStyles();
  const autocompleteDefaults = useDefaults();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canEditHeader, setCanEditHeader] = useState(true);
  const [canChangeInvoiceNumber, setCanChangeInvoiceNumber] = useState(true);
  const [isFetchingRebates, setIsFetchingRebates] = useState(false);
  const [rows, setRows] = useState([defaultRow]);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [isInitialCreate, setIsInitialCreate] = useState(true);
  const rowTotalRebate = rows.reduce((acc, row) => acc + row.rebateDue, 0);
  const [stateFilter, setStateFilter] = useState(null);
  const [setSchoolSearch, schoolResults, schoolsIsLoading] = useAutocomplete(searchEduSchools);
  const filteredSchoolResults = schoolResults.filter((x) => stateFilter === null ? true : x.state === stateFilter.value);
  const currentUser = useSelector((state) => state.users.currentUser);
  const userRoles = useSelector((state) => state.users.roles);
  const currentUserRole = userRoles.find((role) => role.title === currentUser.userRole);
  const isInternal = currentUserRole.internal;
  const orderingFor = useSelector((state) => state.placeOrder.orderingFor);
  const canView = !!orderingFor || !!currentUser.company;

  useEffect(() => {
    if (isInternal) {
      if (!orderingFor) {
        dispatch(setPromptOrderingFor(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInternal]);

  const { handleSubmit, control, reset, formState: {errors}, register, setValue, watch, getValues } = useSmartForm({
    schema: rebateSchema,
    defaultValues: {
      "invoiceDate": null,
      "invoiceNumber": '',
      "studentName": '',
      "school": null,
      "schoolInput": '',
      "schoolName": '',
      "schoolCity": '',
      "schoolState": '',
      "debitMemoNumber": ''
    }
  });

  const watchedVars = {
    school: watch('school'),
  }

  function handleAddRow() {
    setRows((prev) => {
      return [...prev, {...defaultRow, id: uuidv4()}];
    })
  }

  if(rows.length === 0){
    handleAddRow();
  }

  function resetData(){
    reset({
      invoiceDate: null,
      invoiceNumber: '',
      studentName: '',
      school: null,
      schoolInput: '',
      schoolName: '',
      schoolCity: '',
      schoolState: '',
      debitMemoNumber: ''
    });
    setRows([defaultRow]);
    setCanEditHeader(true);
    setCanChangeInvoiceNumber(true);
    setIsInitialCreate(true);
    setInvoiceFile(null);
  }

  async function submitRebate(data){
    setIsSubmitting(true);

    if(isInternal && !orderingFor){
      dispatch(setPromptOrderingFor(true));
      setIsSubmitting(false);
      enqueueSnackbar('Please select a dealer', {variant: 'error'});
      return;
    }

    if(moment(data.invoiceDate).isBefore(moment("2024-09-01"))){
      enqueueSnackbar('Invoices prior to 09-01-2024 are not to be posted or updated', {variant: 'error'});
      setIsSubmitting(false);
      return;
    }

    const filteredRows = rows.filter(x => x.product && (x.quantity > 0));

    if(filteredRows.length === 0){
      enqueueSnackbar('Please add at least one product', {variant: 'error'});
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();

    const formattedData = {
      headerData: {...data, invoiceDate: serializeDate(data.invoiceDate) },
      rows: filteredRows,
      custId: orderingFor?.id || null
    }

    for(const [key, value] of Object.entries(formattedData)){
      formData.append(key, JSON.stringify(value));

    }

    if(invoiceFile){
      formData.append('file', invoiceFile, invoiceFile.name);
    }

    const response = await axios.post('/eduRebates', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if(response.data.succeeded){
      enqueueSnackbar(response.data.message, {variant: 'success'});
      resetData();
    }else{
      const rowsToAdjust = response.data.approvedRows;

      if(!!rowsToAdjust){
        setRows((prevState) => {
          let newState = prevState.map((itm) => {
              const matchingRow = rowsToAdjust.find((nr) => nr.id === itm.id)
              if(matchingRow){
                return {
                  id: matchingRow.id,
                  product: matchingRow.product,
                  quantity: matchingRow.quantity,
                  description: matchingRow.product.description,
                  listPrice: matchingRow.listPrice,
                  rebateDue: matchingRow.rebateDue,
                  isApproved: matchingRow.isApproved,
                  debitMemoNumber: matchingRow.debitMemoNumber
                }
              }else{
                return itm;
              }
            })
          return newState;
        })
      }
      enqueueSnackbar(response.data.message || "An error occurred", {variant: 'error'});
    }

    setIsSubmitting(false);
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.value,
  });

  async function getExistingRebates(invoiceNumber, custId){
    setIsFetchingRebates(true);
    const request = await axios.get('/eduRebates', {
    params: {
      invoiceNumber, custId
    }});

    const data = request.data;

    if(data.entries.length > 0){
      const {headerData, entries} = data;
      reset({
        invoiceDate: moment(headerData.invoiceDate),
        invoiceNumber: headerData.invoiceNumber,
        studentName: headerData.studentName,
        school: headerData.school,
        schoolInput: headerData.school?.schoolId || '',
        schoolName: headerData.school?.schoolName || '',
        schoolCity: headerData.school?.city || '',
        schoolState: headerData.school?.state || '',
        debitMemoNumber: headerData.debitMemoNumber
      });

      setRows(entries.map((x) => {
        return {
          id: x.id,
          product: x.product,
          quantity: x.quantity,
          description: x.product.description,
          listPrice: x.listPrice,
          rebateDue: x.rebateDue,
          isApproved: x.isApproved
        }
      }));

      const canEdit = !entries.some(x => x.isApproved);
      setCanEditHeader(canEdit);
      setCanChangeInvoiceNumber(false);
      setIsInitialCreate(false);
    }
    setIsFetchingRebates(false);
  }

  return (
    <div className={clsx(classes.column, 'flex')}>
      {canView &&
        <>
          <div className={clsx(classes.column, classes.firstRowMargin)}>
            <Typography
              variant="h6"
              color="error"
            >
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              ***This app is to be used for invoices dated on or after 09/01/2024***
            </Typography>
            <div className={clsx(classes.row, classes.alignSpaceBetweenCenter)}>
              <Typography className={classes.headerFontSize} variant="h4">
                &nbsp;
                &nbsp;
                &nbsp;
                EDU Rebates
                {orderingFor ? (<span> - <span
                  className={classes.capitalize}>{orderingFor.name.toLowerCase()}</span> - {orderingFor.id}</span>)
                : currentUser.company ? (<span> - <span
                    className={classes.capitalize}>{currentUser.companyName.toLowerCase()}</span> - {currentUser.companyId}</span>)
                  : ""}

              </Typography>
              {isInitialCreate ? (
                <PdfFilePickerButton
                  handleFile={(file) => {
                    setInvoiceFile(file);
                  }}
                  selectedFile={invoiceFile}
                />) : (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!(getValues('invoiceNumber') !== '' && (orderingFor || currentUser.company))}
                  onClick={() => {
                    const queryString = new URLSearchParams({
                      invoiceNumber: getValues('invoiceNumber'),
                      custId: orderingFor?.id || null
                    }).toString();

                    window.open(
                      `https://portal.macgroupus.com/api/eduInvoice?${queryString}`,
                      '_blank'
                    )

                  }}
                >
                  View Invoice
                </Button>
              )
              }
            </div>
          </div>
          <Card className={clsx(classes.column, 'flex')}>
            <CardContent className={clsx(classes.column, 'flex')}>
              <div className={clsx(classes.row)}>
                <FormTextField
                  formProps={{name: 'invoiceNumber', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="Invoice Number"
                  autoFocus={true}
                  onBlur={(e) => {
                    const invoiceNumber = getValues('invoiceNumber');
                    if (invoiceNumber) {
                      getExistingRebates(invoiceNumber, orderingFor?.id || null);
                    }
                  }}
                  helperText={errors.invoiceNumber?.message}
                  required={true}
                  disabled={isSubmitting || isFetchingRebates || !canChangeInvoiceNumber}
                />
                <Spacer/>
                <FormDatePicker
                  className={"flex"}
                  formProps={{name: "invoiceDate", control: control}}
                  label="Invoice Date"
                  size="small"
                  required={true}
                  disabled={isSubmitting || isFetchingRebates || !canEditHeader}
                />
                <Spacer/>
                <FormTextField
                  formProps={{name: 'studentName', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="Student Name"
                  helperText={errors.studentName?.message}
                  required={true}
                  disabled={isSubmitting || isFetchingRebates || !canEditHeader}
                />
                <Spacer/>
                <FormTextField
                  formProps={{name: 'debitMemoNumber', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="Dealer Claim Number"
                  helperText={errors.debitMemoNumber?.message}
                  disabled={isSubmitting || isFetchingRebates}
                />
              </div>
              <Spacer height={20}/>
              <div className={classes.row}>
                <div className={clsx(classes.row, 'flex')}>
                  <Autocomplete
                    {...autocompleteDefaults}
                    className="flex40"
                    size="small"
                    getOptionLabel={(x) => x.value}
                    filterOptions={filterOptions}
                    options={usStates}
                    getOptionSelected={(o, v) => {
                      return o.value === v.value
                    }}
                    value={stateFilter}
                    onChange={(e, v, r) => setStateFilter(v)}
                    renderInput={autocompleteRenderInput({
                      label: "State Filter",
                    })}
                  />
                  <Spacer/>
                  <FormAutocomplete
                    {...autocompleteDefaults}
                    formProps={{name: "school", control: control}}
                    className="flex"
                    size="small"
                    getOptionLabel={(x) => x.id === 'MANUAL' ? 'MANUAL ENTRY' : `${x.schoolName}`}
                    filterOptions={(options, _) => options}
                    loading={schoolsIsLoading}
                    options={[{id: 'MANUAL', schoolId: 'MANUAL'}, ...filteredSchoolResults]}
                    getOptionSelected={(o, v) => {
                      return o.id === v.id
                    }}
                    disabled={isSubmitting || isFetchingRebates || !canEditHeader}
                    onInputChange={(e, value, reason) => setSchoolSearch(value)}
                    onChange={(e, v, r) => {
                      if (v && v.id === 'MANUAL') {
                        setValue('schoolInput', 'MANUAL ENTRY');
                        setValue('schoolName', '');
                        setValue('schoolCity', '');
                        setValue('schoolState', '');
                      } else {
                        setValue('schoolInput', v ? `${v.schoolId}` : '');
                        setValue('schoolName', v ? v.schoolName : '');
                        setValue('schoolCity', v ? v.city : '');
                        setValue('schoolState', v ? v.state : '');
                      }
                    }}
                    renderInputParams={{
                      label: "School",
                      isLoading: schoolsIsLoading,
                      name: "schoolInput",
                      register: register,
                      error: !!errors.school,
                      helperText: errors.school?.message,
                      required: true,
                      placeholder: "Search for a school",
                      disabled: isSubmitting || isFetchingRebates || !canEditHeader,
                      onBlur: (e) => {
                        const school = getValues('school');
                        if (!school) {
                          setValue('schoolInput', '');
                          setValue('schoolName', '');
                          setValue('schoolCity', '');
                          setValue('schoolState', '');
                        }
                      }
                    }}
                  />
                </div>
                <Spacer/>
                <FormTextField
                  formProps={{name: 'schoolName', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="School Name"
                  helperText={errors.schoolName?.message}
                  disabled={!(watchedVars.school?.schoolId === "MANUAL" && canEditHeader) || watchedVars.school?.schoolId !== "MANUAL" || isSubmitting || isFetchingRebates}
                  required={watchedVars.school?.schoolId === "MANUAL"}
                />
                <Spacer/>
                <FormTextField
                  formProps={{name: 'schoolCity', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="School City"
                  helperText={errors.schoolCity?.message}
                  disabled={!(watchedVars.school?.schoolId === "MANUAL" && canEditHeader) || watchedVars.school?.schoolId !== "MANUAL" || isSubmitting || isFetchingRebates}
                  required={watchedVars.school?.schoolId === "MANUAL"}
                />
                <Spacer/>
                <FormTextField
                  formProps={{name: 'schoolState', control: control}}
                  className={"flex"}
                  variant="outlined"
                  size="small"
                  label="School State"
                  helperText={errors.schoolState?.message}
                  disabled={!(watchedVars.school?.schoolId === "MANUAL" && canEditHeader) || watchedVars.school?.schoolId !== "MANUAL" || isSubmitting || isFetchingRebates}
                  required={watchedVars.school?.schoolId === "MANUAL"}
                />
              </div>
              <Spacer height={20}/>
              <div className={classes.row}>
                <CurrencyTextField
                  autoNumericOptions={{
                    decimalPlaces: 2,
                    currencySymbol: '$',
                  }}
                  label="Total Rebate Due"
                  value={rowTotalRebate}
                  disabled={true}
                  className={"flex"}
                />
                <Spacer/>
                <div className="flex"/>
                <Spacer/>
                <div className="flex"/>
                <Spacer/>
                <div className="flex"/>
              </div>
              <TableContainer className='flex'>
                <Table stickyHeader={true}>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left' className={classes.tableColumnHeaders}>
                        Product
                      </TableCell>
                      <TableCell align='right' className={classes.tableColumnHeaders}>
                        Quantity
                      </TableCell>
                      <TableCell align='left' className={classes.tableColumnHeaders}>
                        Description
                      </TableCell>
                      <TableCell align='right' className={classes.tableColumnHeaders}>
                        Sell Price
                      </TableCell>
                      <TableCell align='right' className={classes.tableColumnHeaders}>
                        Rebate Due
                      </TableCell>
                      <TableCell align='center'>
                        <Button
                          color='primary'
                          onClick={() => handleAddRow()}
                          disabled={isSubmitting}
                        >
                          <Add/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => <EntryRow key={uuidv4()} row={row} setAllRows={setRows}/>)}
                  </TableBody>
                </Table>
              </TableContainer>
              <Spacer/>
              <div className={clsx(classes.row, classes.alignEnd)}>
                <Button
                  onClick={() => resetData()}
                  variant='contained'
                  style={(isSubmitting || isFetchingRebates) ? {} : {color: 'white', backgroundColor: 'red'}}
                  disabled={isSubmitting || isFetchingRebates}
                >
                  Cancel
                </Button>
                <Spacer/>
                <Button
                  onClick={handleSubmit(submitRebate)}
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting || isFetchingRebates}
                >
                  Submit
                </Button>
              </div>
            </CardContent>
          </Card>
        </>
      }
    </div>
  );
}

export default PhotoEduRebates;