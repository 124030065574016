import { useHistory, useLocation, useParams } from "react-router";
import clsx from "clsx";
import {
	Button, Card, CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, FormControlLabel,
	makeStyles,
	Slide, SvgIcon, Switch, Tab, Tabs, Tooltip,
	Typography
} from "@material-ui/core";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import {styles} from "../utils/styles";
import {useDispatch, useSelector} from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import {
	asyncGetCategoryProducts,
	asyncGetCategoryProductsSpecial,
	getShoppingCart,
	setShoppingCartLoading
} from "../data/slices/place-order";
import { getProductRows, getSpecialProductRows } from "../components/product-row";
import * as React from "react";
import Spacer from "../components/spacer";
import { TabContext, TabPanel } from "@material-ui/lab";
import * as Icons from "../icons";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
	...styles,
	firstRowMargin: {
		marginTop: 36,
		marginBottom: 36
	},
	headerFontSize: {
		fontSize: 28
	},
	filterButton: {
		width: 125,
		marginRight: 25
	},
	specialColor: {
		color: 'red'
	},
	iconLabelWrapper: {
		flexDirection: "row-reverse"
	}
});

function PlaceOrder(props) {
	// this id is the category number
	const {id} = useParams();
	const location = useLocation();
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [productsLoading, setProductsLoading] = useState(true);
	const [localColumns, setLocalColumns] = useState(getProductRows());
	const [specialLocalColumns, setSpecialLocalColumns] = useState(getSpecialProductRows());
	const [filterDialogOpen, setFilterDialogOpen] = useState(false);
	const [filterState, setFilterState] = useState({
		newMerchandise: true,
		demoMerchandise: true
	});
	const [tabValue, setTabValue] = useState('products');
	const gridApiRef = useGridApiRef();
	const currentUser = useSelector((state) => state.users.currentUser);
	const inSepMode = currentUser.userRole === "EPP";
	const categories = useSelector((state) => state.categories.categories);
	const products = useSelector((state) => state.placeOrder.products);
	const specialProducts = useSelector((state) => state.placeOrder.specialProducts);
	const shoppingCart = useSelector((state) => state.placeOrder.shoppingCart);
	const orderingFor = useSelector((state) => state.placeOrder.orderingFor);
	const matchingCategory = categories.find((b) => b.number.toString() === id);
	const {enqueueSnackbar} = useSnackbar();
	const createProductsWithQuantities = (products, specialProducts, shoppingCart) => {
		const filteredShoppingCart = shoppingCart.filter((c) => !c.isBogo);
		const shoppingCartAsMap = filteredShoppingCart.reduce((p, c) => {
			p[`${c.prodId}-${c.specialProduct}`] = c;
			return p;
		}, {});

		return products.map((p) => {
			const copy = {...p};
			const cartMatch = shoppingCartAsMap[`${p.prodId}-${p.specialProduct}`];
			const specialMatch = specialProducts.find((sp) => sp.prodId === p.prodId);

			copy.cartQuantity = (cartMatch && (cartMatch.specialProduct === p.specialProduct)) ? cartMatch.quantity : 0;
			copy.hasSpecial = !!specialMatch;

			return copy;
		});
	};

	const createSpecialProductsWithQuantities = (specialProducts, shoppingCart) => {
		const filteredShoppingCart = shoppingCart.filter((c) => !c.isBogo);
		const shoppingCartAsMap = filteredShoppingCart.reduce((p, c) => {
			p[`${c.prodId}-${c.specialProduct}`] = c;
			return p;
		}, {});

		return specialProducts.map((p) => {
			const copy = {...p};
			const cartMatch = shoppingCartAsMap[`${p.prodId}-${p.specialProduct}`];

			copy.cartQuantity = (cartMatch && (cartMatch.specialProduct === p.specialProduct)) ? cartMatch.quantity : 0;

			return copy;
		});
	};

	const productsWithQuantities = useMemo(() => createProductsWithQuantities(products, specialProducts, shoppingCart), [products, specialProducts, shoppingCart]);
	const specialProductsWithQuantities = useMemo(() => createSpecialProductsWithQuantities(specialProducts, shoppingCart), [specialProducts, shoppingCart]);

	const loadProducts = async function (filterStateAsArg) {
		if (!productsLoading) {
			setProductsLoading(true);
		}
		dispatch(setShoppingCartLoading(true));
		const gettingCart = dispatch(getShoppingCart());
		const gettingProducts = dispatch(asyncGetCategoryProducts({categoryId: id, ...filterStateAsArg}));
		const gettingProductsSpecial = dispatch(asyncGetCategoryProductsSpecial({categoryId: id}));
		await gettingCart;
		await gettingProducts;
		await gettingProductsSpecial;
		dispatch(setShoppingCartLoading(false));
		setProductsLoading(false);
	};

	useEffect(() => {
		(async () => {
			setTabValue('products');
			await loadProducts(filterState);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		let timer = null;

		if(location.search !== ''){
			const search = location.search.split("&");
			const special = search[0].split("=")[1];
			const prodId = search[1].split("=")[1];

			if(special === "true" && tabValue !== "special"){
				setTabValue('special');
			}

			if(tabValue === "special" && !productsLoading && !!prodId && specialProductsWithQuantities.length > 0 && !!gridApiRef.current){
				timer = setTimeout(() => {
					const prodRowIndex = gridApiRef.current.getRowIndex(parseInt(prodId));
					gridApiRef.current.scrollToIndexes({
						rowIndex: prodRowIndex
					});
					gridApiRef.current.selectRow(parseInt(prodId), true);
				}, 500);
			}
		}
		return () => {
			if(timer){
				clearTimeout(timer);
			}
		}
	},[location.search, gridApiRef.current, productsLoading, tabValue, specialProductsWithQuantities])

	return (
		<>
			<div className={clsx(classes.column, "flex")}>
				<div className={clsx(classes.row, classes.alignSpaceBetweenCenter, classes.firstRowMargin)}>
					<Typography className={classes.headerFontSize} variant="h4">
						&nbsp;
						&nbsp;
						&nbsp;
						{matchingCategory.name} Products
						{orderingFor && <span> - <span
							className={classes.capitalize}>{orderingFor.name.toLowerCase()}</span> - {orderingFor.id}</span>}
					</Typography>
					{tabValue === 'products' &&
						<Button
							className={classes.filterButton}
							variant="contained"
							color="primary"
							onClick={() => setFilterDialogOpen(true)}>
							Filter
						</Button>
					}
				</div>
				<Card className={clsx(classes.column, "flex")}>
					<CardContent className={clsx(classes.column, "flex")}>
						<TabContext value={tabValue} className={clsx(classes.column, "flex")}>
							<Tabs
								value={tabValue}
								indicatorColor='primary'
								onChange={(event, newValue) => {
									if(newValue === 'special' && !specialProducts.length) {
										enqueueSnackbar('No special products available for this category',
											{anchorOrigin:
													{
														horizontal: "center",
														vertical: "bottom"
													},
												variant: 'warning'
											});
									}else if(newValue === 'products' && location.search !== ''){
										history.push(location.pathname)
										setTabValue('products');
									}else{
										setTabValue(newValue);
									}
								}}
							>
								<Tab label="Products" value="products" />
								{!inSepMode &&
									<Tab
										className={specialProducts.length ? classes.specialColor : {}}
										classes={{ wrapper: classes.iconLabelWrapper }}
										disabled={productsLoading}
										icon={<Tooltip
											title={<h3>Limited QTY products available at a VERY SPECIAL PRICE (Only available in this
												Section)</h3>} placement="right">
											<div className={clsx(classes.column)} style={{ marginBottom: 0 }}>
												<SvgIcon
													className={clsx(classes.iconColor)}
													style={{ marginLeft: 3, marginBottom: 0, color: 'rgba(0, 0, 0, 0.54)', width: 30, height: 30 }}
													component={Icons.ExclamationMark}
													viewBox="-2 -2 24 24"
												/>
											</div>
										</Tooltip>}
										label="Special Opportunity"
										value="special"
									/>
								}
							</Tabs>
							{tabValue === "products" &&
								<TabPanel
									value="products"
									className={clsx(classes.column, {
										'flex': tabValue === "products"
									})}
									style={{padding: 0}}
								>
									<DataGridPro
										className='flex'
										columns={localColumns}
										rows={productsLoading ? [] : productsWithQuantities}
										disableColumnSelector={true}
										disableMultipleSelection={true}
										disableSelectionOnClick={true}
										hideFooter={true}
										loading={productsLoading}
										sortModel={[{field: 'prodId', sort: 'asc'}]}
									>
									</DataGridPro>
								</TabPanel>
							}
							{tabValue === "special" && !inSepMode &&
								<TabPanel
									value="special"
									className={clsx(classes.column, {
										'flex': tabValue === "special"
									})}
									style={{padding: 0}}
								>
									<DataGridPro
										className='flex'
										columns={specialLocalColumns}
										apiRef={gridApiRef}
										rows={productsLoading ? [] : specialProductsWithQuantities}
										disableColumnSelector={true}
										disableMultipleSelection={true}
										disableSelectionOnClick={true}
										hideFooter={true}
										getRowId={(row) => row.id}
										loading={productsLoading}
										sortModel={[{field: 'prodId', sort: 'asc'}]}
									>
									</DataGridPro>
								</TabPanel>
							}
						</TabContext>
					</CardContent>
				</Card>
			</div>
			<FilterTools
				isOpen={filterDialogOpen}
				close={() => setFilterDialogOpen(false)}
				filterState={filterState}
				updateFilterState={(filterState) => {
					setFilterState(filterState);
					setFilterDialogOpen(false);
					loadProducts(filterState);
				}}
			/>
		</>
	);


}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function FilterTools({isOpen, close, filterState, updateFilterState}) {
	const classes = useStyles();
	const [tempFilterState, setTempFilterState] = useState({...filterState})

	useEffect(() => {
		if (isOpen) {
			setTempFilterState({...filterState});
		}
		// eslint-disable-next-line
	}, [isOpen]);

	const updateTempState = (event) => {
		setTempFilterState({...tempFilterState, [event.target.name]: event.target.checked});
	}

	return (
		<Dialog
			open={isOpen}
			TransitionComponent={Transition}
			maxWidth="lg"
		>
			<DialogTitle>Filter Product</DialogTitle>
			<DialogContent className={clsx(classes.row)}>
				<FormControlLabel
					control={
						<Switch
							checked={tempFilterState.newMerchandise}
							onChange={(e) => updateTempState(e)}
							name="newMerchandise"
							color="primary"
						/>
					}
					label="New Product"
				/>
				<Spacer width={12}/>
				<FormControlLabel
					control={
						<Switch
							checked={tempFilterState.demoMerchandise}
							onChange={(e) => updateTempState(e)}
							name="demoMerchandise"
							color="primary"
						/>
					}
					label="Demo Product"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={close}>Close</Button>
				<Button color="primary" onClick={() => updateFilterState(tempFilterState)}>Filter</Button>
			</DialogActions>
		</Dialog>
	);
}

export default PlaceOrder;