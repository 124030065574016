import moment from "moment";
import * as yup from "yup";

function formatCurrency(number) {
	if (!number)
		return '0.00';

	return parseFloat(number).toLocaleString('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
}

let appBarRef = null;

function setAppBarRef(val) {
	appBarRef = val;
}

let contentNoPaddingRef = null;

function setContentNoPaddingRef(val) {
	contentNoPaddingRef = val;
}

function formatPhoneNumber(val) {
	if (val?.length !== 10) {
		return val;
	} else {
		return `(${val.substr(0,3)}) ${val.substr(3,3)}-${val.substr(6)}`;
	}
}

function serializeDate(val) {
	if (val) {
		return moment(val).format('YYYY-MM-DD');
	} else {
		return null;
	}
}

const isValidPrefix = (prefix) => {
	const prefixNum = parseInt(prefix, 10);
	return (
		(prefixNum >= 0 && prefixNum <= 12) ||
		(prefixNum >= 21 && prefixNum <= 32) ||
		(prefixNum >= 61 && prefixNum <= 72) ||
		(prefixNum >= 80 && prefixNum <= 80)
	);
};

const isValidChecksum = (routingNumber) => {
	// Weights used in the checksum calculation
	const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1];

	// Calculate weighted sum
	const sum = routingNumber
		.split('')
		.map((digit, index) => parseInt(digit, 10) * weights[index])
		.reduce((acc, curr) => acc + curr, 0);

	return sum % 10 === 0;
};

const abaValidator = yup.string().required('This is required').test(
	'is-valid-aba',
	'Invalid ABA routing number',
	(value) => {
		if (!value) return false;

		// Check if it's exactly 9 digits
		if (!/^\d{9}$/.test(value)) {
			return false;
		}

		// Check first two digits
		if (!isValidPrefix(value.substring(0, 2))) {
			return false;
		}

		// Check checksum
		return isValidChecksum(value);
	}
);

export {formatCurrency, appBarRef, setAppBarRef, contentNoPaddingRef, setContentNoPaddingRef, formatPhoneNumber, serializeDate, abaValidator};